import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  set(scope: string, key: string, value?: any) {
    if (value === undefined)
      localStorage.removeItem(`${scope}:${key}`);
    else
      localStorage.setItem(`${scope}:${key}`, JSON.stringify(value));
  }

  get(scope: string, key: string) {
    const value = localStorage.getItem(`${scope}:${key}`);
    return value ? JSON.parse(value) : undefined;
  }

  remove(scope: string, key: string) {
    localStorage.removeItem(`${scope}:${key}`);
  }

}
