import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { UserInterface, WorkspaceInterface } from 'voxr-shared';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private http = inject(HttpClient);
  private userService = inject(UserService);
  private storageService = inject(StorageService);

  private _workspaces$ = new BehaviorSubject<WorkspaceInterface[] | undefined>(undefined);
  workspaces$ = this._workspaces$.asObservable().pipe(filter(w => w !== undefined));

  private _workspace$ = new BehaviorSubject<WorkspaceInterface | null | undefined>(undefined);
  workspace$ = this._workspace$.asObservable().pipe(filter(w => w !== undefined));

  workspaceId?: string;
  user?: UserInterface | null;
  roles$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this.userService.user$.subscribe(async (user) => {
      this.user = user;
      if (user) {
        this._workspaces$.next(await this.list());
        const workspaceId = this.storageService.get(this.constructor.name, 'workspace-id');
        if (workspaceId)
          this.setWorkspace(workspaceId);
        else
          this._workspace$.next(null);
      }
      else {
        this._workspaces$.next([]);
        if (user)
          this._workspace$.next(null);
      }

    });

    this.workspace$.subscribe(workspace => {
      this.workspaceId = workspace?._id as string;
      this.storageService.set(this.constructor.name, 'workspace-id', workspace?._id || undefined);
    });

  }

  async current() {
    return firstValueFrom(this.http.get<WorkspaceInterface>(`${environment.app.serviceUrl}/workspace/current`));
  }

  async list() {
    return firstValueFrom(this.http.get<WorkspaceInterface[]>(`${environment.app.serviceUrl}/workspace/list`));
  }

  setWorkspace(workspaceOrId: string | WorkspaceInterface) {
    const workspace = workspaceOrId == null ? null
      : typeof workspaceOrId === 'string' ? this._workspaces$.value?.find(w => w._id === workspaceOrId)
        : workspaceOrId;
    this._workspace$.next(workspace || null);
    const roles = [...(workspace?.members?.find(m => m.userId === this.user?._id)?.roles || []), ...(workspace?.ownerId === this.user?._id ? ['owner'] : [])];
    this.roles$.next(roles);
  }

  async save(workspace: Partial<WorkspaceInterface>) {
    const _new = await firstValueFrom(this.http.post<WorkspaceInterface>(`${environment.app.serviceUrl}/workspace/save`, workspace));
    this.setWorkspace(_new);
  }

  async create(workspace: Partial<WorkspaceInterface>) {
    await firstValueFrom(this.http.post<WorkspaceInterface>(`${environment.app.serviceUrl}/workspace/create`, workspace));
    this._workspaces$.next(await this.list());
  }

  private _members: any[] = [];
  async listMembers() {
    if (!this._members?.length) {
      this._members = await firstValueFrom(this.http.get<any[]>(`${environment.app.serviceUrl}/workspace/members`));
    }
    return this._members;
  }

  async getInvitation(workspaceId: string, invitationId: string) {
    return firstValueFrom(this.http.get<{ invitation: any, workspaceName: string }>(`${environment.app.serviceUrl}/workspace/invitation/${workspaceId}/${invitationId}`));
  }

  async invite(emails: string[], roles?: string[]) {
    const res = await firstValueFrom(this.http.post<any[]>(`${environment.app.serviceUrl}/workspace/invite`, { emails, roles }));
    this._members = [];
    return res;
  }

  async acceptInvitation(workspaceId: string, invitationId: string) {
    const res = await firstValueFrom(this.http.post(`${environment.app.serviceUrl}/workspace/invitation/accept`, { workspaceId, invitationId }));
    this._members = [];
    return res;
  }

  async revokeInvitation(invitationId: string) {
    const res = await firstValueFrom(this.http.post(`${environment.app.serviceUrl}/workspace/invitation/revoke`, { invitationId }));
    this._members = [];
    return res;
  }

  async resendInvitation(invitationId: string) {
    const res = await firstValueFrom(this.http.post(`${environment.app.serviceUrl}/workspace/invitation/resend`, { invitationId }));
    this._members = [];
    return res;
  }

  async saveMember(member: any) {
    const res = await firstValueFrom(this.http.post(`${environment.app.serviceUrl}/workspace/member/save`, { member }));
    this._members = [];
    return res;
  }

  async removeMember(memberId: string) {
    return firstValueFrom(this.http.post(`${environment.app.serviceUrl}/workspace/member/remove`, { memberId }));
  }
}
